@import '~@kite/react-kite/dist/react-kite.css';
@import '~@kite/react-kite/dist/kite-icons.min.css';
@import '~@kite/react-kite-plus/dist/react-kite-plus.css';

/************************
***** Light ************
************************/

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_Lt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_Lt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_Lt.ttf') format('ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_LtIt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_LtIt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_LtIt.ttf') format('ttf');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_Lt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_Lt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_Lt.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_LtIt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_LtIt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_LtIt.ttf') format('ttf');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_Lt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_Lt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_Lt.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_LtIt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_LtIt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_LtIt.ttf') format('ttf');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

/************************
***** Normal ************
************************/

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_Rg.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_Rg.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_Rg.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_It.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_It.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_It.ttf') format('ttf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/************************
***** Medium ************
************************/

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_Md.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_Md.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_Md.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_MdIt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_MdIt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_MdIt.ttf') format('ttf');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_Md.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_Md.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_Md.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_MdIt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_MdIt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_MdIt.ttf') format('ttf');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/************************
***** Bold ************
************************/

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_Bd.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_Bd.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_Bd.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_BdIt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_BdIt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_BdIt.ttf') format('ttf');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/************************
***** Xtra Bold ********
************************/

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_XBd.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_XBd.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_XBd.ttf') format('ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_XBdIt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_XBdIt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_XBdIt.ttf') format('ttf');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_XBd.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_XBd.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_XBd.ttf') format('ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spectrum Sans';
  src: local('Sprectrum Sans'),
    url('/fonts/WOFF2/SpectrumSans_W_XBdIt.woff2') format('woff2'),
    url('/fonts/WOFF/SpectrumSans_W_XBdIt.woff') format('woff'),
    url('/fonts/TTF/SpectrumSans_A_XBdIt.ttf') format('ttf');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

body {
  font-family: Spectrum Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  gap: 16px;
  row-gap: 0;
  grid-auto-rows: minmax(min-content, max-content);
}
